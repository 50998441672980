import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { env } from '$env/dynamic/public';
import UserAgentParser from 'ua-parser-js';
import { captureConsoleIntegration } from '@sentry/integrations';

if (env.PUBLIC_SENTRY_DSN && env.PUBLIC_SENTRY_ENVIRONMENT) {
  Sentry.init({
    dsn: env.PUBLIC_SENTRY_DSN,
    tracesSampleRate: 1.0,
    integrations: [captureConsoleIntegration({ levels: ['error', 'warn'] })],

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // If you don't want to use Session Replay, just remove the line below:
    // integrations: [replayIntegration()],
    environment: env.PUBLIC_SENTRY_ENVIRONMENT,
    beforeSend(event) {
      const ua = event.request?.headers?.['User-Agent'];
      if (ua) {
        const uaParser = new UserAgentParser(ua);
        const results = uaParser.getResult();
        if (
          (results?.os?.name?.toLowerCase() ?? '') === 'windows' &&
          (results?.browser?.name?.toLowerCase() ?? '') === 'chrome' &&
          Number(results?.browser?.major ?? '0') < 105
        ) {
          return null;
        }
      }
      return event;
    }
  });
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
